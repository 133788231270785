import {
  type INode,
  resolveVariable,
  useDependentOn,
  useManagerFormValue,
  usePathResolution,
} from '@manager'

export const useDataDependentOn = (
  node: INode,
  parentPath: string | undefined,
  parentValue: Ref<any>,
) => {
  const { evaluate } = useDependentOn(node, parentPath, {
    defaultCondition: 'true',
  })
  const { getRelativePathById } = usePathResolution(parentPath)
  const { getProperty: _getProperty } = useManagerFormValue()

  const getProperty = (dependentOn: number | null | undefined) => {
    const path = getRelativePathById(dependentOn)
    if (path) return _getProperty(resolveVariable(path, 'data'))
  }

  if (node.dataDependentOn) {
    const condition = computed(() => evaluate(node.dataDependentOn))
    watch(
      () => getProperty(node.dataDependentOn?.[0]?.id),
      (value) => {
        if (condition) {
          parentValue.value[node.name] = value ?? null
        }
      },
      { deep: true },
    )
  }
}
